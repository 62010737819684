.steps {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  list-style: none;
}
.step {
  width: 100%;
  max-width: 350px;

  &__title {
    position: relative;
    background-color: $gray-400;
    height: 40px;
    width: 93%;
    display: grid;
    place-items: center;
    color: $gray-900;
    font-family: sans-serif;

    @at-root .step.current {
      .step__title {
        background-color: $primary;
        color: $white;

      }
    }
  }

  &__title:before,
  &__title:after {
    content: "";
    position: absolute;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  &__title:before {
    border-left: 20px solid $white;
    left: 0;
  }
  &__title:after {
    border-left: 20px solid $gray-400;
    right: -20px;

    @at-root .step.current {
      .step__title:after {
        border-left: 20px solid $primary;

      }
    }
  }
  &__info {
    padding: 1rem 0.5rem;
  }
}
