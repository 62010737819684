@mixin make-text-sizes($text-sizes: $text-sizes) {
  @each $index, $text-size in $text-sizes {
    .text-size-#{$index} {
      font-size: $text-size;
    }
    .line-height-#{$index} {
      line-height: $text-size;
    }
  }
}
@include make-text-sizes();