.list-striped  {
  li:nth-child(2n) {
    background-color: $gray-100;
  }
}
.list-hover {
  li {
    transition: opacity .5s ease;

    &:hover {
      opacity: .9
    }
  }
}