.typeahead {
  &__container {
    font: inherit;
  }

  @at-root .form-column-item &__list {
    margin-top: -#{$form-group-margin-bottom};
    left: calc(#{$grid-gutter-width} / 2);
    width: calc(100% - #{$grid-gutter-width});
  }
}
