#main-content {
  margin-top: 3rem;
  min-height: calc(100vh - 130px);
  @at-root .dashboard & {
    position: relative;
    background: url("../../../images/bg-search.jpg") no-repeat fixed center;
    background-size: cover;
    &::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
    &.light:after {
    }
  }
}

.navbar-brand {
  img {
    @include media-breakpoint-down(mg) {
        height: 40px;
    }
  }
}
