.cookiealert {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  opacity: 0;
  border-radius: 0;
  transform: translateY(100%);
  transition: all 500ms ease-out;
  &.show {
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 1000ms;
  }
  a {
    text-decoration: underline
  }
}