@import "~bootstrap/scss/functions";
// Rewrite boostrap var
@import 'custom-variables';
// Typografy
@import 'fonts';

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
//@import "~bootstrap/scss/button-group";
//@import "~bootstrap/scss/input-group";
//@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
//@import "~bootstrap/scss/media";
//@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
@import "~jquery-typeahead/src/jquery.typeahead";

@import "mixins";
@import "vendor/jquery-typeahead";
@import "app/layout/main-content";
@import "app/cases";
@import "components/cookies-alert";
@import "components/tab";
@import "components/collections";
@import "components/list";
@import "components/step-lst";

#search_cases_form_diseases, #search_cases_form_item {
  min-height: calc(0.5rem * 2 + 1.3rem + 2px);
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.btn .mdi {
  margin-left: 5px;
}
