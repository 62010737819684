.case-description {
  position: relative;
  display: inline-block;
  max-height: 72px;
  overflow: hidden;

  &--ellipsis:before {
    content: "...";
    position: absolute;
    padding: 5px 0 0 2px;
    bottom: 0;
    right: 0;
    height: 30px;
    background-color: #fff;
  }
}

.form-control {
  &#country {
    @include media-breakpoint-down(mg) {
      max-width: 200px;
    }
  }
}

